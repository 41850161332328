.validate-modal > .modal-wrapper {
  height: 100%;
  width: 100%;
  border-radius: 0;
  text-align: center;
  overflow: unset;
  border-radius: 0 !important;
}
.web .validate-modal > .modal-wrapper{
  border-radius: var(--okx-box-radius);
}

.modal-content {
  margin-top: 20px;
}

.validate-modal > .modal-wrapper .ion-page .inline-input > ion-text .small-text,
.validate-modal > .modal-wrapper .ion-page .inline-input > label {
  font-size: var(--ion-font-size);
  color: var(--ion-color-gray);
}
.validate-modal > .modal-wrapper .ion-page .inline-input > ion-text,
.validate-modal > .modal-wrapper .ion-page .inline-input > label {  
  flex: 0 0 auto;
  margin-right: 10px;
}
.validate-modal > .modal-wrapper .ion-page .inline-input > ion-input[name='email'] {
  text-overflow: ellipsis;
}
.validate-modal > .modal-wrapper .ion-page .inline-input > ion-input input,
.validate-modal > .modal-wrapper .ion-page .inline-input.inline-input--validate .data-picker-input{
  padding: 5px 0;
}


.web .validate-modal > .modal-wrapper {
  max-width: var(--okx-bsmodal-width-md);
  width: 100%;
  height: 85%;
}

.web .validate-modal > .modal-wrapper .validate-modal-closer {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.web .validate-modal > .modal-wrapper .validate-modal-closer ion-icon {
z-index: 5;
}

.web .validate-modal > .modal-wrapper .validate-modal-header > br {
  display: none;
}

.web .validate-modal > .modal-wrapper .validate-modal-header {
  text-align: left;
}


.web .validate-modal > .modal-wrapper .validate-modal-header ion-text .small-text {
  color: var(--ion-color-gray);
}

.web .validate-modal .modal-classic-closer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  font-size: 20px;
}