.payment-card-input {
	border: 0;
	outline: none;
	flex: 1;
	padding-left: 0;
	font-size: var(--ion-font-size);
	background-color: transparent;
	color: var(--ion-color-secondary);
}
.payment-card-input::placeholder {
	color: var(--okx-textfield-color) !important;
	opacity: 0.4;
	font-size: var(--ion-font-size);
}
input::placeholder,
textarea::placeholder {
	color: var(--okx-textfield-color) !important;
	opacity: 0.4;
}
textarea,
input {
	color: var(--okx-textfield-color) !important;
}
.scrollable-y.add-card-scroll {
	overflow: unset;
	overflow-y: unset;
}
.input-field-two-columns-wrapper .strong-text,
.input-field-two-columns-wrapper .normal-text {
	color: var(--okx-box-wrapper-color) !important;
}
.web-stripe-input {
	height: 15px;
	display: flex;
	flex: 1;
	align-items: center;
	/* border-bottom: 1px solid
    var(
      --ion-item-border-color,
      var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13)))
    ); */
}
.web-stripe-input input {
	color: var(--okx-textfield-color) !important;
}
.web-stripe-input > .__PrivateStripeElement {
	width: 100%;
	height: 15px;
}

.card-list ion-item {
	flex: 1;
}

.card-add-wrapper .data-picker-input {
	border-bottom: 1px solid;
	border-color: var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
}

.web .scrollable-y.add-card-scroll {
	overflow: hidden;
	overflow-y: auto;
}

.web .route-card-add .ion-padding ion-button:last-child {
	margin-bottom: 0;
}

.web #main > ion-header.route-card-add {
	z-index: 100;
}
