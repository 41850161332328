.cards-list-card-image ion-icon {
	height: 30px;
	width: 30px;
	margin-right: 15px;
}
.box-content ion-label {
	margin: 0 !important;
}
.cards-list-card ion-button {
	--background: transparent !important;
}
.cards-list-card {
	margin-bottom: 15px;
}
.no-cards-box {
	background-color: transparent !important;
	border: 2px dashed var(--okx-box-wrapper-color);
	border-radius: var(--okx-box-radius);
	opacity: 0.5;
	display: flex;
	padding: var(--ion-padding);
	/* align-content: center; */
	align-items: center;
}
.no-cards-box * {
	color: var(--okx-box-wrapper-color);
}
.order-summary-card {
	margin-bottom: 15px;
}
.card-icon{
	height: 35px;
	width: 35px;
	color: var(--okx-box-wrapper-color);
	position: absolute;
}
.order-summary-card.item-radio-checked {
	background-color: var(--ion-color-black) !important;
	color: var(--ion-color-primary-contrast);
}
.order-summary-card.item-radio-checked * {
	color: var(--ion-color-primary-contrast) !important;
}
.order-summary-card.item-radio-checked ion-icon {
	color: var(--ion-color-primary-contrast);
}
.order-summary-card ion-radio {
	width: 40px;
	opacity: 0;
}
.remove-card-icon{
  right: 0;
  width: 25px;
  height: 25px;
}
