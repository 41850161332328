.restaurant-card {
	border-radius: var(--ion-card-radius);
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	margin: 0;
	background: transparent;
}
.snoozed {
	text-decoration: line-through;
	text-decoration-color: var(--ion-color-gray-shade);
}
.click-collect-pickers-backdrop {
	position: fixed;
	top: 0;
	right: 0;
	background-color: black;
	opacity: 0.3;
	height: 100%;
	width: 100%;
	z-index: 3;
}

.click-collect-dialog {
	position: fixed;
	margin: 0 auto;
	background-color: #fff;
	width: 80%;
	height: 50%;
	border-radius: 10px;
	top: 55%;
	left: 50%;
	transform: translate(-50%, -45%);
	transition: all 0.3s ease-out;
	visibility: hidden;
	opacity: 0;
	pointer-events: none;
	z-index: 999;
}

.click-collect-dialog.show-up {
	visibility: visible;
	opacity: 1;
	transform: translate(-50%, -50%);
	pointer-events: auto;
}

.click-collect-dialog-layout {
	height: 100%;
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	padding: var(--ion-padding);
	background-color: var(--okx-background-color);
	border-radius: var(--okx-box-radius);
}

.click-collect-dialog.sc-ion-modal-md {
	border-radius: var(--border-radius);
	width: var(--width);
	min-width: var(--min-width);
	max-width: var(--max-width);
	height: var(--height);
	min-height: var(--min-height);
	max-height: var(--max-height);
	border-width: var(--border-width);
	border-style: var(--border-style);
	border-color: var(--border-color);
	background: var(--background);
	-webkit-box-shadow: var(--box-shadow);
	box-shadow: var(--box-shadow);
	overflow: var(--overflow);
	z-index: 10;
}

.click-collect-dialog-header {
	flex: 0 1 auto;
	padding: 0;
}

.click-collect-dialog-header h3 {
	font-size: var(--okx-title-font-size);
	font-weight: bold;
	margin: 0;
}

.click-collect-dialog-closer {
	position: absolute;
	top: var(--ion-padding) !important;
	right: var(--ion-padding) !important;
	padding: 0;
	cursor: pointer;
}

.click-collect-dialog-closer ion-icon {
	font-size: 20px;
	fill: var(--ion-color-secondary);
}

.click-collect-dialog-content {
	flex: 1 1 auto;
	padding: 0;
	margin: 15px 0;
	overflow-y: auto;
	max-height: 50vh;
}
.web .click-collect-dialog-content {
	max-height: 500px;
}
.click-collect-dialog-action {
	flex: 0 1 auto;
}

.click-collect-dialog-action ion-button {
	height: 36px;
}

.web ion-content.route-pick-up-at-counter,
.web ion-content.route-pick-up-at-counter-scheduled {
	--ion-color-base: transparent;
}

.route-pick-up-at-counter .absolute-content,
.route-pick-up-at-counter-scheduled .absolute-content {
	padding: 0;
}
.route-pick-up-at-counter .click-and-collect-title,
.route-pick-up-at-counter-scheduled .click-and-collect-title {
	padding: var(--ion-padding);
	flex: unset;
}
.route-pick-up-at-counter .click-and-collect-locations,
.route-pick-up-at-counter-scheduled .click-and-collect-locations {
	flex: 1;
	overflow: auto;
	margin-bottom: 20px;
}
.route-pick-up-at-counter .click-and-collect-time,
.route-pick-up-at-counter-scheduled .click-and-collect-time {
	height: 70%;
}
.route-pick-up-at-counter .click-and-collect-locations .box-wrapper,
.route-pick-up-at-counter-scheduled .click-and-collect-locations .box-wrapper {
	padding: 0;
	margin: 0 var(--ion-padding) 10px;
}
.route-pick-up-at-counter .click-and-collect-button-wrapper,
.route-pick-up-at-counter-scheduled .click-and-collect-button-wrapper {
	padding: var(--ion-padding);
	flex: unset;
}
.route-pick-up-at-counter .mbsc-select-input.mbsc-control,
.route-pick-up-at-counter-scheduled .mbsc-select-input.mbsc-control {
	display: none !important;
}
.click-and-collect-timepicker-wrapper {
	margin: 0 var(--ion-padding);
	padding: 0 !important;
	overflow: hidden;
}
.click-collect-items ion-item {
	margin: 0 var(--ion-padding);
}
.striked {
	text-decoration: line-through;
}
.click-and-collect-timepicker-wrapper .mbsc-ios .mbsc-sc-itm {
	font-size: 1.5em !important;
}
