.gift-voucher-order-content {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}

.gift-voucher-success {
	background-color: #c9fdd7 !important;
	display: flex;
	align-items: center;
}
.gift-voucher-success ion-icon {
	stroke: #1e7105;
	height: 50px;
	width: 50px;
	margin-right: 20px;
}
