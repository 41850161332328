.route-social .square {
  background-color: var(--ion-color-primary);
  display: flex;
  position: relative;
  border-radius: 25px;
  padding: 15px;
  justify-content: center;
  margin-bottom: 10px;
  cursor: pointer;
  position: relative;
}
.route-social .square .normal-text {
  color: var(--ion-color-primary-contrast);
}
.soc-icon {
  height: 20px;
  width: 20px;
  position: absolute;
  left: 15px;
  top: 0;
  bottom: 0;
  margin: auto;
}
.soc-icon ion-icon {
  height: 20px;
  width: 20px;
  fill: var(--ion-color-primary-contrast);
}
