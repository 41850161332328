.table-number-input {
	--padding-start: 0;
}

.route-order-to-table .absolute-content {
	padding: 0;
}
.route-order-to-table .click-and-collect-title {
	padding: var(--ion-padding);
	flex: unset;
}
.route-order-to-table .click-and-collect-locations {
	height: 60%;
	overflow: auto;
	margin-bottom: 20px;
}
.route-order-to-table .click-and-collect-time {
	height: 60%;
}
.order-to-table-locations {
	padding: 0 var(--ion-padding);
	/* height: 70%; */
}
.route-order-to-table .click-and-collect-locations .box-wrapper {
	padding: 0;
	margin: 0 var(--ion-padding);
}
.route-order-to-table .click-and-collect-button-wrapper {
	padding: var(--ion-padding);
	flex: unset;
}
.unclickable {
	pointer-events: none;
}
