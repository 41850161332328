.social-login-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.social-login-wrapper iframe div {
  width: 100% !important;
}
.google-login-button {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}
.google-login-button > div {
  height: 44px !important;
}
.google-login-button .button-inner {
  justify-content: flex-start;
}
#appleid-signin div {
  width: 100% !important;
  max-width: 100% !important;
}
#appleid-signin div div {
  height: 2.8em !important;
}
