.feedback-label {
  font-size: 10px;
  color: var(--ion-feedback-label);
  margin: 5px;
  font-weight: bold;
}

.radio-row {
  padding-left: 10px;
}

.stars-row {
  padding: 10px;
}

.star {
  font-size: 20px;
  color: var(--ion-color-primary);
  margin-right: 5px;
  cursor: pointer;
}
.box-wrapper.default-color .stars-row .star {
  color: var(--ion-color-primary) !important;
}
.box-holder-top {
  margin-top: 20px;
}

.separator {
  height: 1px;
  background-color: var(--ion-separator-color);
  margin: 0 5px;
}

.native-textarea.sc-ion-textarea-ios {
  padding-inline-start: 5px;
}

/* .commentService {
  height: 50px;
}

.commentTech {
  height: 160px;
} */

.commentTech ion-textarea {
  --padding-start: 0;
}

.item.sc-ion-label-ios-h,
.item .sc-ion-label-ios-h {
  font-weight: bold;
  margin-left: 2px;
}

.item.sc-ion-label-ios-h,
.item .sc-ion-label-md-h {
  margin-left: 2px;
}

.web ion-content.route-feedback {
  background-repeat: no-repeat;
  background-position: 100% 0;
  background-size: 100% auto;
}

.web .route-feedback .ion-padding .heading-wrapper,
.web .route-feedback .ion-padding .frm-wrapper,
.web .route-feedback .ion-padding .action-wrapper {
  padding: 0;
}

.web .route-feedback .ion-padding .frm-wrapper {
  position: absolute;
  left: 32px;
  right: 32px;
  top: 100px;
  bottom: 100px;
  overflow-y: auto;
  --ion-color-success: var(--ion-color-primary);
}

.web .route-feedback .ion-padding .action-wrapper {
  position: absolute;
  left: 32px;
  right: 32px;
  bottom: 0;
  padding-bottom: 32px;
}

.web .route-feedback .ion-padding ion-button:last-child {
  margin-bottom: 0;
}
ion-content.route-feedback .box-holder ion-list,
ion-content.route-feedback .box-wrapper {
  padding: 0;
}
.box-wrapper .stars-row {
  height: 60px;
  display: grid;
  align-items: center;
}
.box-wrapper .stars-row:first-of-type {
  border-bottom: 1px solid var(--ion-color-gray);
}
.route-feedback ion-radio::part(container){
  --border-radius: 50% !important;
	border-radius: 50%;

} 
.route-feedback ion-radio.radio-checked::part(container) {
  background: var(--ion-color-primary);
}