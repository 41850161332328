ion-modal.modal-classic {
	--width: 70%;
	/* --height: var(--okx-modal-height) !important; */
	--border-radius: 10px;
}

.web ion-modal.modal-classic.quantity-modal {
	--height: 20% !important;
	--width: var(--okx-bsmodal-width-sm);
}

ion-modal.modal-classic.quantity-modal .modal-classic-action ion-button {
	text-transform: uppercase;
}

ion-modal.modal-classic .modal-classic-wrapper {
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
	padding: 0px 13px;
}

ion-modal.modal-classic .modal-classic-content {
	flex: 1 1 auto;
	overflow-y: auto;
	padding: var(--safe-area-inset-top, 12px) 12px 12px 12px;
}

ion-modal.modal-classic .modal-classic-closer {
	position: absolute;
	top: var(--safe-area-inset-top, 12px);
	right: 12px;
	padding: 0;
	cursor: pointer;
	z-index: 2;
	color: var(--okx-box-wrapper-color);
	font-size: 20px !important;
}

ion-modal.modal-classic .modal-classic-header,
ion-modal.modal-classic .modal-classic-action {
	flex: 0 1 auto;
}

ion-modal.modal-classic .modal-classic-header {
	padding: 10px 5px;
}

ion-modal.modal-classic .modal-classic-header h3 {
	font-size: 14px;
	font-weight: bold;
	margin: 0;
}

ion-modal.modal-classic.modal-with-color-header .modal-classic-header h3 {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	margin-right: 20px;
}

ion-modal.modal-classic.modal-with-color-header .modal-classic-wrapper::after {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	height: 42px;
	background-color: var(--ion-color-primary);
	z-index: -1;
}

ion-modal.modal-classic.modal-with-color-header .modal-classic-closer {
	padding: 11px;
	font-size: 20px;
}
.customIncrementModal {
	--min-height: 250px !important;
	--height: 250px;
}
.modal-wrapper {
	--background: var(--okx-background-color);
}

.web ion-modal.modal-classic {
	--height: var(--okx-modal-height) !important;
	--width: var(--okx-bsmodal-width-lg);
}

ion-modal.modal-classic .modal-classic-header {
	display: flex;
  justify-content: space-between;
  padding: var(--safe-area-inset-top, 30px) var(--ion-padding) 0 var(--ion-padding);
}

ion-modal.modal-classic .modal-classic-header .title {
	line-height: 1.2;
}

ion-modal.modal-classic .modal-classic-header .modal-classic-header-closer {
	font-size: 20px;
}

@media screen and (max-width: 1200px) {
	.web ion-modal.modal-classic {
		--width: var(--okx-bsmodal-width-md);
	}
}
@media screen and (max-width: 960px) {
	.web ion-modal.modal-classic {
		--width: var(--okx-bsmodal-width-sm);
	}
}
.modal-back-arrow-closer {
	position: absolute;
	left: 0;
	top: 30px;
	z-index: 10;
	margin: 0 var(--ion-padding);
	font-size: var(--okx-title-font-size);
}