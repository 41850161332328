.route-order ion-toolbar.primary-toolbar {
	--background: transparent no-repeat 50% 50% / auto 90%;
	/* background-color: var(--ion-color-secondary); */
	/* --background: var(--ion-color-secondary) url(../../assets/images/nav-logo-new.png) no-repeat 50% 50%/auto 90%; */
}

/* .route-order + ion-toolbar.dynamic-header {
  --ion-toolbar-background: var(--ion-color-secondary);
} */

.route-order .segment-holder {
	padding: 0;
}

.order-categories {
	flex: 0 0 auto;
	padding: 0;
}

.order-categories h2 {
	padding: 0 25px;
}

.order-categories-inner {
	display: flex;
	flex-flow: row;
	width: 100%;
	overflow-y: auto;
}

.order-content {
	flex: 1 1 100%;
	overflow: hidden;
	padding: 0 16px;
}

.category-button {
	--color: var(--ion-color-secondary);
	font-weight: bold;
	margin: 10px 0 !important;
	border-bottom: 1px solid var(--ion-color-primary);
	opacity: 0.5;
	font-size: var(--ion-font-size) !important;
}
.web .category-button {
	margin: 0 0 15px !important;
}
.category-button.active {
	--background-activated: var(--ion-color-primary);
	--color: var(--ion-color-secondary);
	border-bottom: 2px solid var(--ion-color-primary);

	opacity: 1;
}

.order-list-items {
	overflow-y: auto;
	height: 100%;
	/* padding-bottom: 70px; */
}
.grid-menus .order-list-items {
	overflow: hidden;
}

.order-list-items > .list-md {
	padding-bottom: 0;
	position: relative;
}

.order-list-items ion-item {
	--min-height: 20px;
	--inner-padding-end: 0;
	cursor: pointer;
}

.order-list-item {
	display: flex;
	width: 100%;
	justify-content: space-around;
	align-items: center;
	padding: 10px;
	background-color: var(--okx-box-wrapper-background);
	margin-bottom: 10px;
	border-radius: var(--okx-box-radius);
	min-height: 70px;
	align-items: center;
	justify-content: space-between;
}
.order-list-group {
	display: block;
	list-style-type: none;
	margin-block-start: 0;
	margin-block-end: 0;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	padding-inline-start: 0;
	border-radius: var(--okx-box-radius);
	overflow: hidden;
}

.order-list-group > div > ion-item {
	border-radius: var(--okx-box-radius);
	overflow: hidden;
}
.order-list-item > div {
	flex: 0 1 auto;
}
.order-list-item > div:first-child {
	margin-left: 0;
	display: flex;
	justify-content: flex-end;
}

.order-list-item > div:nth-child(3) {
	text-align: right;
}

.item-content {
	display: flex;
	flex-direction: column;
}
.item-content p {
	margin: 0;
}
.item-image {
	border-radius: 5px;
	background: none transparent no-repeat 50% 50%;
	background-color: var(--ion-color-gray);
	background-size: 100% auto;
	width: 50px;
	padding-top: 100%;
	margin-left: auto;
	min-width: 50px;
	min-height: 50px;
	width: 50px;
	height: 50px;
	background-size: contain;
	background-position: center;
	position: relative;
	overflow: hidden;
}
.item-image::before {
	content: '';
	height: 100%;
	width: 100%;
	position: absolute;
	left: 0;
	top: 0;
	background-color: rgba(0, 0, 0, 0.05);
}
.item-price > span:first-child {
	color: var(--okx-box-wrapper-color);
	font-size: 13px;
	font-weight: bold;
}
.item-price > span.original-price {
	text-decoration: line-through;
	font-size: 0.9em;
}
.order-list-header {
	padding: 25px;
	margin-bottom: 10px;
}

.order-list-header ion-label {
	font-size: 25px;
	font-weight: 600;
	text-transform: initial;
}

.order-sublist-header {
	padding-left: 0;
	position: sticky;
	position: -webkit-sticky;
	top: -1px;
	background-color: transparent;
	z-index: 2;
	--color: var(--ion-color-primary);
	margin-top: 20px;
}

.order-sublist-header.empty {
	/* max-height: 0;
	height: 0px;
	overflow: hidden;
	min-height: 0; */
	display: none;
}

.order-sublist-header ion-label {
	font-size: var(--okx-title-font-size);
	text-transform: initial;
	color: var(--ion-color-primary);
	margin-top: 0;
	font-weight: normal;
}

.order-intro-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100%;
	width: 100%;
	display: flex;
	flex-flow: column;
	align-items: center;
	background: var(--ion-color-light-shade);
}

.order-intro-image-wrapper {
	flex: 1 1 0%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--ion-color-black);
	background: var(--ion-color-light-shade);
	width: 100%;
}

.order-intro-content {
	height: 250px;
	padding: 25px;
	border-top-left-radius: 35px;
	border-top-right-radius: 35px;
	background: var(--ion-color-medium-contrast);
}

.order-intro-content p {
	color: var(--ion-color-black);
	text-align: center;
	padding: 0 10px;
	font-size: 14px;
	margin: 25px 0;
}

.order-intro-modal > .modal-wrapper {
	height: 320px;
	width: 270px;
	border-radius: 15px;
}

.order-intro-modal > .modal-wrapper > .ion-page {
	min-width: 100%;
	padding: 15px 25px;
}

.order-intro-modal > .modal-wrapper > .ion-page ion-icon {
	position: absolute;
	right: 15px;
	font-size: 35px;
}

.order-intro-modal > .modal-wrapper > .ion-page h2 {
	font-size: 20px;
	margin-top: 4px;
}

.order-intro-modal > .modal-wrapper > .ion-page .order-intro-modal-inner {
	flex: 1 1 0%;
	display: flex;
	flex-flow: column;
	justify-content: space-around;
}

.location-select {
	--padding-start: 0;
	margin-top: 5px;
	border-bottom: 1px solid var(--ion-color-medium);
}

.popover-content ion-item ion-label {
	margin-left: 10px;
}

.pickup-time {
	margin-top: 5px;
	border-bottom: 1px solid var(--ion-color-medium);
}

.app {
	display: grid;
	grid-template-columns: 20% 80%;
}
.navbar {
	padding: 0.5rem;
}
.navbar .nav-link {
	padding: 0.5rem;
}
.header {
	height: 80px;
	overflow: auto;
	background: #aaa;
}
.container {
	height: 500px;
	background: #ddd;
}
.gap {
	height: 500px;
}
.gap.short {
	height: 250px;
}
.gap.tall {
	height: 1000px;
}
.container.relative {
	overflow-y: auto;
}

.view-order-button {
	position: fixed;
	bottom: 10px;
	text-align: center;
	z-index: 3;
	left: 14px;
	right: 14px;
}

.segment-holder.grid-menus {
	overflow: auto !important;
}
.segment-holder.grid-menus .order-categories {
	display: flex;
	flex-direction: column;
}
.order-categories-tiles-wrapper {
	width: 100%;
}

.order-categories-tiles-wrapper {
	grid-column: span 2;
}
.order-categories-tile {
	height: 150px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-size: cover;
	background-position: center;
	border-radius: 10px;
	margin: 0;
	background-color: rgba(0, 0, 0, 0.4);
	background-blend-mode: darken;
	text-align: center;
	padding: 20px;
}
.grid-menus .order-categories {
	padding: 0;
}
.route-order > .no-padding > .segment-holder.grid-menus > .order-categories > ion-text > span,
.grid-menus .title {
	padding-left: 30px;
	padding-bottom: 10px;
}
.grid-menus .order-categories-tile-title {
	color: var(--ion-color-white);
	font-size: var(--okx-subtitle-font-size);
	font-weight: bold;
	text-transform: uppercase;
}
.grid-menus .order-list-item {
	display: grid;
	margin: 0;
	gap: 10px;
	grid-template-areas:
		'image'
		'desc'
		'price';
	justify-items: flex-start;
	justify-content: unset;
	padding: 0;
	border-bottom: 0;
}
.grid-menus .order-list-items ion-item {
	--border-style: unset;
	background-color: var(--okx-box-wrapper-background);
	padding: 10px !important;
	border-radius: var(--okx-box-radius);
}
.grid-menus .order-list-items ion-item .small-text {
	color: var(--okx-box-wrapper-color);
}
.grid-menus .item-content {
	margin: 0;
	grid-area: desc;
}
.grid-menus .item-content > div {
	display: none;
}
.grid-menus .item-price {
	grid-area: price;
	margin: 0;
}
.grid-menus .item-icon-wrapper {
	grid-area: icon;
}
.grid-menus .item-icon-wrapper ion-icon {
	height: 20px;
	width: 20px;
	opacity: 0.4;
}
.grid-menus .item-price > span:first-child {
	font-size: var(--ion-font-size);
	font-weight: 400;
}
.grid-menus .default-height {
	display: none;
}
.grid-menus .item-content > h5 {
	font-size: var(--ion-font-size);
	font-weight: bolder;
	font-weight: 900;
	letter-spacing: normal;
	min-height: 40px;
	text-align: start;
}
.grid-menus .order-list-item > div:first-child {
	flex: unset;
}
/* .grid-menus .order-list-item > .item-price{
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0 0 0 var(--okx-box-radius);
  padding: 5px;
  background-color: var(--okx-box-wrapper-background);
  z-index: 1;
} */
.grid-menus .item-image-wrapper {
	width: 100%;
	margin: 0;
	grid-area: image;
}
.grid-menus .item-image::before {
	content: '';
	height: 150px;
	width: 100%;
	position: absolute;
	left: 0;
	top: 0;
	background-color: rgba(0, 0, 0, 0.1);
}
.grid-menus .item-image {
	width: 100%;
	height: 150px;
	padding: 0;
	background-position: center;
	background-color: var(--ion-color-gray);
	background-size: cover;
}

.grid-menus .category-title {
	margin: 10px 10px 10px 30px;
	color: var(--okx-box-wrapper-color);
	padding: 0;
	font-weight: normal;
}
.grid-menus .order-sublist-holder {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}

.grid-menus .order-list-items ion-list {
	display: grid;
	gap: var(--ion-padding);
	padding: 0 var(--ion-padding) var(--ion-padding);
	grid-template-columns: 1fr 1fr;
}

.have-items-in-basket .order-list-items ion-list {
	padding-bottom: 60px;
}
.order-header-content > * {
	display: flex;
	align-items: center;
}
.order-header-content svg {
	height: 25px;
	width: 25px;
	margin-right: 15px;
}

.order-header-content > div:nth-child(2) {
	justify-content: space-between;
	margin-top: 10px;
}
.no-items-message {
	display: block;
	margin: 0 var(--ion-padding);
}
@media (max-width: 700px) {
	.app {
		grid-template-columns: unset;
	}
}

@supports (-webkit-overflow-scrolling: touch) {
	/* iOS */
	/* .order-list-items {
    height: 100vh;
  } */
}
