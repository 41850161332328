.block-separator {
	display: flex;
  justify-content: space-between;
}
.separator-line {
	width: 40%;
	height: 1px;
	background-color: var(--ion-color-dark);
	content: '';
	margin: auto;
	z-index: -1;
	opacity: 0.2;
}
.block-separator .normal-text {
	display: block;
	padding: 0 20px;
	background-color: transparent;
}
.block-separator {
	margin: 15px 0;
}