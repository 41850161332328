.vocher-balance-result {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}
.vocher-balance-result-error {
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: var(--ion-padding);
}
.vocher-balance-result svg {
	stroke: var(--okx-box-wrapper-color);
	height: 50px;
	width: 50px;
}
.vocher-balance-result-error > *:not(:last-child) {
	margin-bottom: 10px;
}
.vocher-balance-result .pill {
	text-transform: capitalize;
	align-items: center;
	display: flex;
}
.vocher-balance-result .pill svg {
	margin-right: 5px;
	height: 15px;
	width: 15px;
	stroke: var(--ion-color-primary-contrast);
}
