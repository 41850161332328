.terms-wrapper {
  display: flex;
  flex-direction: column;
}

.terms-wrapper p,
.terms-wrapper a,
.terms-wrapper b {
  margin: 0;
  font-size: var(--ion-font-size);
  font-weight: 600;
}

.terms-wrapper b {
  margin-top: 10px;
  display: block;
}