ion-content div.refer-a-friend {
	background-repeat: no-repeat;
	background-position: 50% 40px;
	background-size: 100% auto;
}

ion-content div.refer-a-friend::before {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	display: block;
	content: '';
	background-image: linear-gradient(#fff, rgba(255, 255, 255, 0) 40%);
}

.raf-card {
	border-radius: var(--ion-card-radius);
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	margin: 0;
}

.raf-card ion-card-content {
	font-size: var(--ion-font-size);
	padding: var(--ion-padding);
}

.raf-card ion-card-content .raf-info-title {
	padding: 0 2px;
	font-weight: bold;
}

.raf-card ion-card-content .raf-info {
	padding: 15px 15px 30px;
	text-align: center;
}

.web ion-content.route-refer-a-friend {
	/*background-image: url(../../assets/images/refer-a-friend.jpg);*/
	background-repeat: no-repeat;
	background-position: 100% 0;
	background-size: 100% auto;
}

.web ion-content div.refer-a-friend {
	background: transparent none;
}

.web .route-refer-a-friend .title-bar {
	display: none;
}

.web .route-refer-a-friend ion-card ion-item {
	--border-color: var(--ion-color-light);
}
.refer-friend-icon {
	width: 44px;
	height: 44px;
}
